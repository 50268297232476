import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { resolve } from "dns";
import { SessionService } from "src/app/session-service/session.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-onboard-benefit",
  templateUrl: "./onboard-benefit.component.html",
  styleUrls: ["./onboard-benefit.component.scss"],
})
export class OnboardBenefitComponent implements OnInit {
  @ViewChild("fileClass") fileClass: ElementRef;
  @Output() benefitsAndValues = new EventEmitter();
  @ViewChild('errorInExcel') errorInExcel: TemplateRef<HTMLDivElement>;

  excelErrors = [];
  insurerTheming: any;
  constructor(private modalService: NgbModal, private sessionservice: SessionService) { }

  ngOnInit(): void {
    this.insurerTheming = this.sessionservice.get("insurerTheming");

  }

  parseBenefitValues(benefitValues) {
    let benefitValuePayloads = benefitValues.map((row) => {
      let benefitValuePayload = {};
      benefitValuePayload["valueHeader"] = row.portal_value? row.portal_value:row.tob_value ;
      benefitValuePayload["benefitValueCode"] = row.benefit_value_code ?  row.benefit_value_code  :  row.benefitValueCode ;
      benefitValuePayload["tobValue"] = row.tob_value ? row.tob_value:row.portal_value ;
      benefitValuePayload["factor"] = row.type.toLowerCase().trim() === "percentage" ? row.factor * 100 : row.factor;
      benefitValuePayload["factorType"] = row.type;
      benefitValuePayload["isDefault"] = row.is_default ?row.is_default: 0;
      benefitValuePayload["benefitValueId"] = row.benefit_value_id? row.benefit_value_id:"";
      benefitValuePayload["notApplicableValue"]=row.not_applicable_value? row.not_applicable_value:row.not_applicable_value;
      benefitValuePayload['benefitConfig'] = row.benefit_config ?row.benefit_config:null

      return benefitValuePayload;
    });
    return benefitValuePayloads;
  }

  parseCopayOptions(copayOptions) {
    let copayOptionPayLoads = copayOptions.map((row) => {
      let copayOptionPayLoad = {};
      copayOptionPayLoad['headerValue'] = row.parent_value ? row.parent_value : row.parent_value;
      copayOptionPayLoad['childValue'] = row.child_value ? row.child_value : row.child_value;
      copayOptionPayLoad['childValueId'] = row.child_value_id ? row.child_value_id : row.child_value_id;
      copayOptionPayLoad['factor'] = row.factor ? row.factor : row.factor;
      copayOptionPayLoad['factorType'] = row.factor_type ? row.factor_type : row.factor_type;
      return copayOptionPayLoad;
    });
    return copayOptionPayLoads
  }

  async readExcel(event) {
    const res: any = await this.fileToExcel(event);
    let benefitHeaderPayloads = res.headerSheetRows.map((row) => {
      let benefitHeaderPayload = { benefitHeaderId: row.benefit_header_id };
      benefitHeaderPayload["benefitHeader"] = row.hasOwnProperty("portal_header") ? row.portal_header : row.tob_header;
      benefitHeaderPayload["benefitValue"] = row.hasOwnProperty("portal_value") ? row.portal_value : row.tob_value;
      benefitHeaderPayload['benefitLink'] = row.hasOwnProperty('copay_dependency_parent_id') ? row.copay_dependency_parent_id : "";
      benefitHeaderPayload["benefitType"] = row.hasOwnProperty("is_optional") ? (row.is_optional ? "Optional" : "Mandatory") : "Mandatory";
      benefitHeaderPayload["isEditable"] = row.hasOwnProperty("is_broker_portal_visible") ? row.is_broker_portal_visible : 0;
      benefitHeaderPayload['groupDetails'] = row.hasOwnProperty('group') ? row.group : "";
      benefitHeaderPayload["tobHeader"] = row.hasOwnProperty("tob_header") ? row.tob_header : "";
      benefitHeaderPayload["tobValue"] = row.hasOwnProperty("tob_value") ? row.tob_value : "";
      benefitHeaderPayload['benefitDependencyLink'] = row.hasOwnProperty('benefit_dependency_parent_id') ? row.benefit_dependency_parent_id : "";
      benefitHeaderPayload['applicableLink'] = row.hasOwnProperty('applicable_rule') ? row.applicable_rule : "";
      benefitHeaderPayload['visibilityRule'] = row.hasOwnProperty('visibility_rule') ? row.visibility_rule : "";
      benefitHeaderPayload['benefitHeaderCode'] = row.hasOwnProperty('benefit_header_code') ? row.benefit_header_code : "";
      //retrived copayDependencyLookup
      benefitHeaderPayload['copayDependencyLookup'] = row.hasOwnProperty('copay_dependency_lookup') ? row.copay_dependency_lookup : "";
      let matchedBenefitValues = [];
        matchedBenefitValues = res.valueSheetRows.filter((valueRow) => {
          return valueRow["benefit_header_id"] === benefitHeaderPayload["benefitHeaderId"];
        });
        if (matchedBenefitValues.length == 0) {
          matchedBenefitValues = [{ portal_value: benefitHeaderPayload["benefitValue"],tob_value: benefitHeaderPayload["tobValue"], factor: 0, type: "percentage", is_default: 1, benefitValueCode : benefitHeaderPayload['benefitHeaderCode'] + '-1'  }];
          // throw "Bad request Exception"
        }
      const parsedBenefitValues = this.parseBenefitValues(matchedBenefitValues);
      benefitHeaderPayload["benefitValues"] = parsedBenefitValues;
      //looping through benefitValues to filter lookup sheet row values
     benefitHeaderPayload["benefitValues"].map((row)=>{
       let matchedLookupValues = [];
       //benefit values that has combinations
      matchedLookupValues = res.lookupSheetRows?.filter((valueRow) => {
        return valueRow["benefit_value_id"] === row["benefitValueId"];
      });
       //parsing into required key format
       const parsedCopayOptions = this.parseCopayOptions(matchedLookupValues)
       row["copayOptions"] = parsedCopayOptions;
     })
      return benefitHeaderPayload;
    });

    if (benefitHeaderPayloads.length) {
      //validation if both of benefitLink and copayDependencyLookup ha values -> invalid
      let isValid = true;
      benefitHeaderPayloads.map((benefit) => {
        if (benefit['benefitLink'] != "" && benefit['copayDependencyLookup'] != "") {
          this.excelErrors.push({
            position: benefit['benefitHeaderId'],
          });
          isValid = false
        }
      });
      //if valid emitting
      if (isValid) {
        this.benefitsAndValues.emit(benefitHeaderPayloads);
      }
      //else throw error in modal
      else {
        this.modalService.open(this.errorInExcel, {
          windowClass: "error-model",
          backdrop: "static",
        });
      }
    }
    this.fileClass.nativeElement.value = "";
  }

  async fileToExcel(event) {
    return new Promise((resolve, reject) => {
      if (event.target.files.length > 0) {
        try {
          let benefitsFile = event.target.files[0];
          let fileReaderxlsx = new FileReader();
          fileReaderxlsx.readAsArrayBuffer(benefitsFile);
          fileReaderxlsx.onload = async (_e) => {
            let b: any = fileReaderxlsx.result;
            var xlsxdata = new Uint8Array(b);
            var array = new Array();
            for (var i = 0; i != xlsxdata.length; ++i) {
              array[i] = String.fromCharCode(xlsxdata[i]);
            }
            var bstr = array.join("");
            var workbook = XLSX.read(bstr, {
              type: "binary",
            });
            var first_sheet_name = workbook.SheetNames[0];
            var header_sheet = workbook.Sheets["benefit_header"];
            var value_sheet = workbook.Sheets["benefit_value"];
            var lookup_sheet = workbook.Sheets?.["benefit_lookup"];

            let headerSheetRows = XLSX.utils.sheet_to_json(header_sheet);
            let valueSheetRows = XLSX.utils.sheet_to_json(value_sheet);
            let lookupSheetRows=XLSX.utils.sheet_to_json(lookup_sheet);

            resolve({ headerSheetRows, valueSheetRows,lookupSheetRows });
          };
        } catch (exception) {
          reject(exception);
        }
      } else {
        reject("No File");
      }
    });
  }

  cnacelPopup() {
    this.modalService.dismissAll()
  }
}
