<div class="container-fluid py-5" style="height: 100vh;" *ngIf="!enableNotFound">
    <div class="">
        <div class="row">
            <div class="home-logo ">
              <!-- {{logo}} -->
            <img class="home-image" alt="homeInsurance-image" src={{logo}} >
            <!-- <img class="home-image" alt="homeInsurance-image" src="assets/icons/axa-re.png" *ngIf="showAxaLogo" [ngClass]="{'axaLogoImage':showAxaLogo}"></div> -->
            </div>
            <div class="mt-2">
                <div class="col-5 p-0 parent-login-wrapper position-absolute">
                    <div class="login-wrapper  py-5" *ngIf="templateName =='signIn' ">
                        <div class="row">
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <div class="">
                                        <img src="assets/icons/waving hand.png" alt="homeInsurance-hand" *ngIf="insurerTheming != 'orientinsurance'">
                                        <img src="assets/icons/waving-hand-orient.svg" alt="homeInsurance-hand" *ngIf="insurerTheming == 'orientinsurance'" class="orient-hand">

                                    </div>
                                </div>
                                <div class="align-self-center ps-2">
                                    <div class="semi-bold welcome">Welcome Back </div>
                                    <div class="regular userDetails">Please enter your details</div>
                                </div>
                            </div>
                        </div>
                        <form class="mt-5" [formGroup]="loginForm" (ngSubmit)="login()">
                            <div class="user-feild">
                                <label class="light">Email Id</label>
                                <div>
                                    <input autocomplete="new-email"  type="text" class="userIdTextbox w-100 p-1" formControlName="email" [ngClass]="{ 'is-invalid': submitted && formControls['email']['errors']}">
                                    <div *ngIf="submitted && formControls['email']['errors']" class="invalid-feedback">
                                        <div class="invalid-feedback d-block" *ngIf="formControls['email']['errors']['required']">
                                          Email is required</div>
                                          <div class="invalid-feedback d-block"*ngIf="formControls['email']['errors']['email']">Email must be a valid email address</div>
                                      </div>
                                </div>
                            </div>
                            <div class="password-feild">
                                <label class="light">Password</label>
                                <div class="position-relative"><input autocomplete="off" type=text  class="PasswordTextbox w-100 p-1" formControlName="password"  [ngClass]="{ 'is-invalid': submitted && formControls['password']['errors'],'hidepasswordText':!show }">                                    <img src="assets/icons/password-show.svg" (click)="show=!show" aria-hidden="true" *ngIf="show" class="inputbox">
                                    <img src="assets/icons/password-hide.svg"  (click)="show=!show" aria-hidden="true" *ngIf="!show" class="inputbox">

                                    <div *ngIf="submitted && formControls['password']['errors']" class="invalid-feedback">
                                        <div class="invalid-feedback d-block" *ngIf="formControls['password']['errors']['required']"> Password is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="forgot-password regular" role="button" (click)="openForgotPasswordTemplates()">Forgot Password?</div>
                            <!-- Captcha generation -->
                            <div *ngIf="validateCaptch">
                                <p class="mt-3">Please enter the code displayed below:</p>

                                <span><input class="captcha-input" type="text" formControlName="captchaText" [ngClass]="{ 'is-invalid': submitted && formControls['captchaText'].errors}"></span>
                                <div *ngIf="submitted && formControls['captchaText'].errors" class="invalid-feedback">
                                    <div class="invalid-feedback d-block" *ngIf="formControls['captchaText']['errors']['required']">
                                    Captcha is required</div>
                                </div>
                                <span class="captch-image">{{ captchaCode }}</span>
                                </div>
                            <div class="login-button-parent">
                                <div class="">
                                    <button class="login-button semi-bold" type="submit">Login</button>
                                </div>
                            </div>
                        </form>
                        <div class="or text-center medium">
                            <div> Or</div>
                        </div>
                        <div class="signuptext text-center">
                            <div class="semi-bold"> Sign up</div>
                        </div>
                        <div class="createNewAccount text-center">
                            <div class="light"> To create new account</div>
                        </div>

                    </div>
                    <div *ngIf="templateName == 'forgotPassword'" class="forgot-wrapper mt-5">
                      <app-forgot-password (passwordUpdated)="passwordUpdatedNotifications($event)"></app-forgot-password>

                    </div>
                </div>
                <div class="col-7 p-0 align-items-center column-second">
                    <div class="">
                        <img src="assets/icons/login-illus.png" class="loginpageImage">
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class=" d-flex justify-content-end me-4">
            <div class="align-items-end helpLine semi-bold">
                <img src="assets/icons/need-help.png" class="phone-icon ms-4"> 8766755457
                <div class="regular">Do you need our help?</div>
            </div>
        </div> -->
    </div>

</div>
<div *ngIf="enableNotFound">
    <app-not-found></app-not-found>

</div>
