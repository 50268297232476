import { premiumCalculation } from "./common-premiumroundup-calculation";
import { insuranceHousepremiumCalculation } from "./insurancehouse-premiumroundup-calculation";

export class PremiumService {
    premiumRoundUp(insurer: any, premium,optionalParam = null) {
      let updatedPremium;
      if ((insurer === "newton" || insurer === "takafulemarat" || insurer === "insurancehouse") && optionalParam == 'gwpRoundOff' || (insurer === "takafulemarat" && optionalParam == 'displayRoundOff')) {
        updatedPremium = new insuranceHousepremiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      } else {
        updatedPremium = new premiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      }
    }
  }